import { defineMessages } from 'react-intl';

export const sequencesMessages = defineMessages({
  SEQUENCE_NUMBER: {
    defaultMessage: 'Séquence {sequenceNumber, number}',
  },

  SEQUENCES_COUNT: {
    defaultMessage: '{count, plural, one {# séquence} other {# séquences}}',
  },

  MODULES_COUNT: {
    defaultMessage: '{count, plural, one {# module} other {# modules}}',
  },
});

export const lessonsMessages = defineMessages({
  AVAILABLE_IN: {
    defaultMessage: 'Ce cours sera disponible dans',
  },

  LESSON: {
    defaultMessage: 'Cours',
  },
});
