/* eslint-disable react/jsx-filename-extension */
import ReactDOM from 'react-dom/client';

import { bootstrapSentryReact } from '@eversity/services/sentry/react';
import {
  bootstrapLocaleAdapter,
  getDefaultLocale,
  makeLocalStorageAdapter,
  setAppLocale,
} from '@eversity/utils/web';

import config from './config/env';
import App from './react/context';
import { importLocale } from './services/intl';

const initLocale = async () => {
  bootstrapLocaleAdapter(makeLocalStorageAdapter, config.app.name);
  const locale = getDefaultLocale();
  await importLocale(locale);
  setAppLocale(locale);
};

(async function main() {
  await Promise.all([initLocale(), bootstrapSentryReact(config.sentry)]);
  ReactDOM.createRoot(document.getElementById('root')).render(<App />);
})();
