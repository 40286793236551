import { USER_ROLES } from '@eversity/domain/constants';

/**
 * Check if user can remove a role
 *
 * @param userRoles - Roles of the user who requests to remove a role from another user.
 * @param role - Role to be removed.
 * @returns - True if the user can remove the role, false otherwise.
 */
export const canUserAddOrRemoveRole = (
  userRoles: USER_ROLES[],
  role: USER_ROLES,
) => {
  if (role === USER_ROLES.SUPER_ADMIN) {
    return false;
  }

  if (userRoles.includes(USER_ROLES.SUPER_ADMIN)) {
    return true;
  }

  if (
    userRoles.includes(USER_ROLES.ADMIN) ||
    userRoles.includes(USER_ROLES.ADMINISTRATIVE)
  ) {
    if (role !== USER_ROLES.ADMIN && role !== USER_ROLES.SUPPORT) {
      return true;
    }
  }

  return false;
};
