import { getEnvironmentVariable } from '@eversity/utils/web';

export default {
  app: {
    name: 'eversity/backoffice',
    env: getEnvironmentVariable('ENVIRONMENT'),
  },

  sentry: {
    dsn: getEnvironmentVariable('BACKOFFICE_WEB_SENTRY_DSN'),
    env: getEnvironmentVariable('ENVIRONMENT'),
    release: `backoffice-web@${process.env.BUILD_VAR_SENTRY_RELEASE}`,
    enabled: getEnvironmentVariable('BACKOFFICE_WEB_SENTRY_ENABLED', {
      transform: (v) => v === 'true',
      defaultValue: getEnvironmentVariable('SENTRY_ENABLED', {
        transform: (v) => v === 'true',
        defaultValue: true,
      }),
    }),
  },
} as const;
