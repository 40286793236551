import {
  type ExternalContent,
  type GetExternalContentsQuery,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

export class ExternalContentsRepository extends HttpRepository {
  /**
   * Get external contents.
   * @param query - Query.
   * @param query.contentType - Content type.
   * @param query.externalId - External ID.
   * @returns External contents.
   */
  async getExternalContents(
    query?: GetExternalContentsQuery,
  ): Promise<ExternalContent[]> {
    const { body } = await this.http
      .get('/api/v1/school/external-contents')
      .query(query);

    return body;
  }
}
