import { commonRoutes } from '@eversity/ui/domain';

export default {
  // Routes below are for the unauthenticated user
  ...commonRoutes,

  HOME: commonRoutes.APP,

  COURSES: {
    ROOT: '/courses',
    COURSE: '/courses/:courseId',

    TEACHING_UNITS: {
      TEACHING_UNIT:
        '/courses/:courseId/teaching-units/:teachingUnitId/settings',

      LESSON:
        '/courses/:courseId/teaching-units/:teachingUnitId/lessons/:lessonId',
    },
  },

  LESSONS: {
    ROOT: '/lessons',
    LESSON: '/lessons/:lessonId/',
    LESSON_VERSION: '/lessons/:lessonId/versions/:versionNumber/',
    LESSON_VERSION_SEQUENCE:
      '/lessons/:lessonId/versions/:versionNumber/sequences/:sequenceVersionId',
  },

  ASSIGNMENTS: {
    ROOT: '/assignments',
    ASSIGNMENT: '/assignments/:assignmentId',
  },

  ASSIGNMENT_SUBMISSIONS: {
    ROOT: '/assignment-submissions',
  },

  USERS_INTERNAL: {
    ROOT: '/users-internal',
  },

  USERS_STUDENTS: {
    ROOT: '/users-students',
  },

  NEWS: {
    CREATE: '/news/create',
    EDIT: '/news/edit/:newsId',
    LIST: '/news/list',
  },

  VIRTUAL_CLASSROOMS: {
    ROOT: '/virtual-classrooms',
    VIRTUAL_CLASSROOM: '/virtual-classrooms/:virtualClassroomId',
  },

  LEGAL_DOCUMENTS: {
    ROOT: '/legal-documents',
  },

  AI_INTERACTIONS: {
    ROOT: '/ai-interactions',
  },
} as const;
