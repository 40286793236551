import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { LESSON_CARD_THUMBNAIL_ASPECT_RATIO } from '@eversity/domain/constants';

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 235px;
`;

export const card = (theme: Theme) => css`
  position: relative;

  width: 100%;
  aspect-ratio: ${LESSON_CARD_THUMBNAIL_ASPECT_RATIO};

  border: 1px solid ${theme.colors.gray[100]};
  border-radius: 8px;

  overflow: hidden;

  margin-bottom: 10px;

  &.isDisabled {
    opacity: 0.2;
  }

  &.isExternal {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        180deg,
        ${theme.colors.gray[900]} 0%,
        transparent 100%
      );
    }
  }
`;

export const progressImage = (
  theme: Theme,
  imageSrc: string,
  progress: number,
) => {
  const startOpacityRgba = new TinyColor(theme.colors.gray[0])
    .setAlpha(0)
    .toRgbString();
  const endOpacityRgba = new TinyColor(theme.colors.gray[0])
    .setAlpha(0.8)
    .toRgbString();

  const backgroundWithGradient = css`
    background-image: linear-gradient(
        90deg,
        ${startOpacityRgba} 0%,
        ${startOpacityRgba} ${progress}%,
        ${endOpacityRgba} ${progress}%,
        ${endOpacityRgba} 100%
      ),
      url(${imageSrc});
  `;

  const background = css`
    background-image: url(${imageSrc});
  `;

  return css`
    position: absolute;

    width: 100%;
    aspect-ratio: ${LESSON_CARD_THUMBNAIL_ASPECT_RATIO};

    ${progress ? backgroundWithGradient : background};
    background-size: cover;
    background-position: center;
  `;
};

export const placeholderImage = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: ${theme.colors.primary[15]};
`;

export const progressTextBackground = (theme: Theme) => {
  const textShadowRgba = new TinyColor(theme.colors.gray[900])
    .setAlpha(0.1)
    .toRgbString();

  return css`
    position: absolute;
    bottom: 0;
    left: 10px;

    -webkit-text-stroke: 4px ${theme.colors.gray[0]};
    /* These are not the exact model values because they overlapped with the text stroke. */
    text-shadow:
      4px 0px 4px ${textShadowRgba},
      0px 4px 4px ${textShadowRgba};
  `;
};

export const progressText = (theme: Theme) => css`
  position: absolute;
  bottom: 0;
  left: 10px;

  color: ${theme.colors.primary[500]};
`;

export const subText = css`
  display: flex;
  align-items: center;
  gap: 12px;

  &.isDisabled {
    opacity: 0.6;
  }
`;

export const tags = css`
  display: flex;
  align-items: center;
  gap: 4px;

  margin-bottom: 2px;
`;

export const info = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const completionTime = (theme: Theme) => css`
  padding: 0 10px;

  border-left: 1px solid ${theme.colors.gray[100]};
`;

export const logoContainer = css`
  position: absolute;
  top: 8px;
  left: 8px;

  z-index: 2;
`;
