import { type LocaleBundle } from '@eversity/types/misc';

export const availableLocales = ['fr', 'en'];

export const DEFAULT_APP_LOCALE = 'fr';

export const importLocale = async (locale: string): Promise<LocaleBundle> => {
  const { default: bundle } = await import(`../../intl/locales/${locale}`);
  return bundle;
};
